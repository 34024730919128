<template>
  <div class="searchTag">
    <pageHead title="话题页" />
    <div
      class="searchTag_content"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="50"
    >
      <topic
        v-for="(item, index) in topicData"
        :key="item.id"
        :data="item"
        class="topicItem"
        @comment="handleComment"
        @userInfoClick="handleToUserDetail(item.userBasicVO)"
        @share="handleShare"
        @like="like($event, index)"
        @favorite="favorite($event, index)"
        @originalClick="handleTopicClick"
        @click="handleTopicClick"
        @attClick="handleToUserDetail(item.userBasicVO)"
        @handleNewActive="handleNewActive"
      />
      <no-more v-if="!listQueryParams.hasNextPage" />
    </div>
    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-img
      @publish="handlePublishComment"
      :attList="attList"
    />
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import topic from '@/components/topicItem'
import NoMore from '@/components/uitls/NoMore'
import commentToolDialog from '@/components/commentToolDialog'
import {articleByTag} from '@/api/article'
import {addThumbsup, removeThumbsup} from '@/api/thumbsup'
import {addFavorites, removeFavorites} from '@/api/favorites'
import {addComment} from '@/api/comment'

const ENTITY_TYPE_ARTICLE = 2

export default {
  components: {
    pageHead,
    topic,
    NoMore,
    commentToolDialog
  },
  created() {
    this.listQueryParams.topicTitle = this.$route.query.tag
  },
  data() {
    return {
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true
      },
      topicData: [],
      commentToolDialog,
      showDialog: false,
      commentParams: {},
      attList: [],
      commentContent: '',
      isAnonymousComment: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'organization') {
        if (vm.$route.query.selectMembers) {
          vm.$route.query.selectMembers.split(',').forEach((item) => {
            let index = item.indexOf(':')
            const attName = item.substring(index + 1)
            vm.attList.push({
              attId: item.substring(0, index),
              attName: attName
            })
          })
        }
        vm.showDialog = true
        vm.commentParams =
          localStorage.getItem('commentParams') &&
          JSON.parse(localStorage.getItem('commentParams'))
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'organization') {
      localStorage.setItem('commentParams', JSON.stringify(this.commentParams))
    }
    next()
  },
  methods: {
    loadMore() {
      this.loadList()
    },
    loadList() {
      let listQueryParams = this.listQueryParams
      listQueryParams.isVote = 0
      if (listQueryParams.hasNextPage) {
        articleByTag(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.pageNo < res.totalPage
          listQueryParams.pageNum = listQueryParams.pageNum + 1
          let resData = this.topicData
          res.records.forEach((item) => {
            let count = resData.filter(
              (innerItem) => innerItem.articleId === item.articleId
            )
            if (count.length === 0) {
              resData.push(item)
            }
          })
          this.topicData = resData
        })
      }
    },
    handleTopicClick({articleId} = {}) {
      this.$router.push({name: 'topicDetail', query: {id: articleId}})
    },
    like(res, index) {
      console.log(res)
      this.topicData[index].isThumbsUp = !res.isThumbsUp
      // 取消点赞
      if (res.isThumbsUp) {
        let data = this.topicData[index]
        let count = data.articleStatisticVO.thumbsUpCount - 1
        if (count < 0) count = 0
        data.articleStatisticVO.thumbsUpCount = count
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        })
      } else {
        // 添加点赞
        let data = this.topicData[index]
        // if (!data.articleStatisticVO) {
        //   data.articleStatisticVO = {}
        // }
        data.articleStatisticVO.thumbsUpCount =
          data.articleStatisticVO.thumbsUpCount + 1
        addThumbsup({
          entityId: res.articleId,
          entityType: 1
        })
      }
    },

    favorite(res, index) {
      this.topicData[index].isFavorites = !res.isFavorites
      // 取消收藏
      if (res.isFavorites) {
        let data = this.topicData[index]
        let count = data.articleStatisticVO.favoritesCount - 1
        if (count < 0) count = 0
        data.articleStatisticVO.favoritesCount = count
        removeFavorites({
          entityId: res.articleId,
          entityType: '1'
        })
      } else {
        let data = this.topicData[index]
        // if (!data.articleStatisticVO) {
        //   data.articleStatisticVO = {}
        // }
        data.articleStatisticVO.favoritesCount =
          data.articleStatisticVO.favoritesCount + 1
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: '1'
        })
      }
    },
    handleNewActive(item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId
      })
      console.log(this.topicData[index])
      this.topicData[index].articleVoteVO = item
    },
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
    },
    async handlePublishComment() {
      const {
        commentContent: content,
        commentParams: {articleId: entityId},
        isAnonymousComment: isAnonymous,
        attList
      } = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({message: '评论内容不能为空', type: 'danger'})
        return
      }
      const atUserIds = attList.map((item) => {
        return item.attId
      })
      await addComment({
        content,
        entityId,
        entityType,
        isAnonymous,
        atUserIds
      })
      this.attList = []
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ''
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item
        }
        const {articleStatisticVO} = item
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1
          }
        }
      })
    },
    handleShare({id: quoteId} = {}) {
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    handleToUserDetail(userInfo) {
      const {userId} = userInfo || ''
      this.$router.push({
        name: 'userInfoDetail',
        params: {userId}
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.searchTag {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.searchTag_content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.mainView {
  height: 100%;
  overflow-y: auto;

  &_content {
    position: relative;

    .topicItem {
      margin-bottom: 10px;
    }
  }
}
</style>
